body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body{
  background-color: black;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

// animation for slowly comining up
@keyframes slowly-come-up {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slowly-come-down {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
.show{
  display: flex !important;
}
.bottom-menu{
  display:flex;
  // background-color: #2c2c2c;
  height: 8vh;
opacity: 0;
  justify-content: space-evenly;
  align-items: center;
  
  animation: slowly-come-down 0.5s ease-in-out;
}
.bottom-menu-area:hover {
  .bottom-menu{
   opacity: 1;
    animation: slowly-come-up 0.5s ease-in-out;
   
  }

}
.bottom-menu-area-flash{
  background-color: yellow;
}